<template>
  <el-cascader
    ref="myCascader"
    :disabled="disabled"
    :value="value"
    :props="props"
    :options="deptTree"
    @input="change($event)"
  />
</template>

<script>
import { groupTree } from '@/api/user'
export default {
  name: 'GroupTree',
  props: {
    value: {
      type: [String, Number, Array],
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      props: {
        value: 'id',
        label: 'name',
        selfAccounting: 'selfAccounting',
        checkStrictly: true
      },
      deptTree: []
    }
  },
  async mounted() {
    const { datas } = await groupTree()
    this.deptTree = this.removeEmpty(datas)
  },
  methods: {
    handleChange(value) {
      //  console.log('eeeee', this.$refs["myCascader"].getCheckedNodes()[0].pathLabels)
      //       console.log('yyy', this.$refs["myCascader"].getCheckedNodes()[0].label)
    },
    removeEmpty(list) {
      list.forEach(e => {
        if (e.children.length) {
          this.removeEmpty(e.children)
        } else {
          delete e.children
        }
      })
      return list
    },
    change(val) {
      this.$emit('input', val)
      this.$emit('seletChange', val)
      this.$emit('handleLabelChange', this.$refs['myCascader'].getCheckedNodes()[0].data)
    }
  }
}
</script>

<style>

</style>
